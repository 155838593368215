/* ----- ELEMENTI IN COMUNE -----*/

    .projectPopup .mobile-version{
        display: none;
    }
    .projectPopup .tablet-version{
        display: none;
    }
    .projectPopup .tablet-version-inline{
        display: none;
    }
    .projectPopup .desktop-version{
        display: block;
    }
    .projectPopup{
        height: 100%;
        width: 100%;
        background-color: white;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        overflow-y: overlay;
        overflow-x: hidden;
        z-index: 1;
    }
    .layoutHeader{
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
    .projectPopup .layoutHeader .header{
        height: 32px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-end;
    }
    .projectPopup .layoutHeader .header .title{
        color: #ffc9c9;
        font-size: 60px;
    }
    .projectPopup .layoutHeader .header .closeIcon{
        color:black;
        cursor: pointer;
        height: 32px;
        width: 32px;
    }
    .layoutRestrictions{
        max-width: calc(1366px - 64px);
        padding: 0px 32px 0px 32px;
        margin: auto;
        display: flex;
        flex-direction: row;
    }
    .layoutRestrictionsHeader{
        padding: 44px 32px 64px 32px;
        margin: auto;
        max-width: calc(1366px - 64px);
    }
    .firstSection{
        padding-top: 140px;
    }
    .projectPopup .layoutRestrictions .content{
        flex: 1;
    }
    .projectPopup .layoutRestrictions .content p.titles{
        color:#212121;
        font-size: 32px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        margin-top: 85px;
        margin-bottom: 35px;
    }
    .projectPopup .layoutRestrictions .content p.descriptions{
        color:#212121;
        margin: 0;
        padding: 0;
        font-size: 24px;
        line-height: 36px;
        font-weight: 300;
        margin-bottom: 85px;
    }
    .projectPopup .layoutRestrictions .paddingColumns{
        flex: 0 0 275px;
    }
    span.boldText{
        font-weight: 600;
    }
    .projectPopup .paddingColumns p.title{
        padding: 0;
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        color: #757575;
        margin-bottom: 21px;
    }
    .projectPopup .paddingColumns p.anno{
        margin-bottom: 47px;
    }
    .projectPopup .paddingColumns p.pfinali{
        margin-top: 31px;
    }
    .projectPopup .paddingColumns p.subtitle{
        padding: 0;
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        color: #212121;
    }
    .projectPopup .paddingColumns p.subtitle:not(.end){
        margin-bottom: 12px;
    }
    .layoutSection1{
        background-color: white;
    }
    .projectPopup .layoutSection1 .content p.projectTitle{
        margin: 0;
        padding: 0;
        font-size: 46px;
        line-height: 54px;
        font-weight: 600;
        color: #212121;
        margin-top: -9px;
    }
    .projectPopup .layoutSection1 .content p.description{
        margin: 0;
        padding: 0;
        font-size: 24px;
        line-height: 36px;
        font-weight: 300;
        color: #212121;
        margin-top: 48px;
        margin-bottom: 85px;
    }
    .projectPopup .layoutSection1 .content img.projectPreviewImage{
        height: 568px;
        width: 100%;
    }







/* ----- OLLIN PROJECT ----- */

    /* --- LAYOUT 1 --- */
    /* is in common from projects */


    /* --- LAYOUT 2 --- */
    .layoutSection2-ollin{
        background-color: #fbe7e6;
    }
    .projectPopup .layoutSection2-ollin .content{
        display: flex;
        justify-content: center;
    }
    .projectPopup .layoutSection2-ollin .content img.brandIdentityImage{
        height: 950px;
    }


    /* --- LAYOUT 3 --- */
    .layoutSection3-ollin{
        background-color: white;
    }
    .projectPopup .layoutSection3-ollin img.uikitImage{
        height: 1137px;
    }


    /* --- LAYOUT 4 --- */
    .layoutSection4-ollin{
        background-color: #fbe7e6;
        height: 80vh;
    }
    .layoutSection4-ollin .layoutRestrictions{
        height: 100%;
    }
    .layoutSection4-ollin .layoutRestrictions .content{
        margin: 0px 163px 0px 163px;
    }

    /* --- LAYOUT 5 --- */
    .layoutSection5-ollin{
        background-color: white;
    }
    .projectPopup .layoutSection5-ollin .content p.descriptions{
        margin-bottom: 35px;
    }
    .projectPopup .layoutSection5-ollin .appAccessibilityPreview{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column{
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .image1{
        height: 628px;
        position: relative;
        left: -15px;
    }
    .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .image2{
        height: 628px;
        position: relative;
        right: -15px;
    }
    .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .imageTitle{
        color:#212121;
        font-size: 14px;
        font-weight: 600;
        margin-top: 8px;
        margin-bottom: 14px;
    }
    .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .imageDescription{
        color:#212121;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
    }
    .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .imageTitle.n2{
        position: relative;
        right: -33px;
    }
    .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .imageDescription.n2{
        position: relative;
        right: -33px;
    }
    .projectPopup .layoutSection5-ollin .projectPreviewLink{
        text-decoration: underline;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-color: #212121;
        text-decoration-thickness: 2px;
    }
    .projectPopup .layoutSection5-ollin .linkPreview{
        cursor: pointer;
    }
    .projectPopup .layoutSection5-ollin p.descriptions.end{
        margin-bottom: 116px;
    }


/* ----- 21PILOTS PROJECT ----- */
    /* --- LAYOUT 2 --- */
    .layoutSection2-21pilots{
        background-color: white;
    }
    .projectPopup .layoutSection2-21pilots .content{
        display: flex;
        justify-content: center;
    }
    .projectPopup .layoutSection2-21pilots .content img.moodboardImage{
        height: 1031px;
    }

    /* --- LAYOUT 3 --- */
    .layoutSection3-21pilots{
        background-color: white;
    }

    /* --- LAYOUT 4 --- */
    .layoutSection4-21pilots{
        background-color: white;
    }
    .layoutSection4-21pilots .selectorLayout{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35px;
    }
    .layoutSection4-21pilots .content div.titles{
        color: #212121;
        font-size: 32px;
        font-weight: 600;
        margin: 0;
        padding: 0;
    }
    .layoutSection4-21pilots .content .modeSelector{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .layoutSection4-21pilots .content .modeSelector .mode{
        background-color: white;
        cursor: pointer;
        color: #212121;
        padding: 9px 17px 9px 17px;
        font-size: 18px;
        font-weight: 500;
        z-index: 2;
    }
    .layoutSection4-21pilots .content .modeSelector .mode:not(.end){
        /*margin-right: 16px;*/
    }
    .layoutSection4-21pilots .content .modeSelector .mode.selected{
        background-color: black;
        color: white;
        text-decoration: underline;
    }
    .layoutSection4-21pilots .content .prototipo{
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 6px 12px rgba(0,0,0,16%);
        margin: 0 auto 120px auto;
        overflow-y: overlay;
        overflow-x: hidden;
    }
    .layoutSection4-21pilots .content .prototipo.desktop{
        height: 424px;
        width: 100%;
    }
    .layoutSection4-21pilots .content .prototipo.tablet{
        height: 643px;
        width: 483px;
    }
    .layoutSection4-21pilots .content .prototipo.mobile{
        height: 548px;
        width: 258px;
    }
    .layoutSection4-21pilots .content .prototipo img{
        width: 100%;
    }



/* ----- HODLEND PROJECT ----- */

    /* --- LAYOUT 2 --- */
    .layoutSection2-hodlend{
        background-color: #0069F8;
    }
    .projectPopup .layoutSection2-hodlend .content{
        display: flex;
        justify-content: center;
    }
    .projectPopup .layoutSection2-hodlend .content img.brandIdentityImage{
        height: 1036px;
    }

    /* --- LAYOUT 3 --- */
    .layoutSection3-hodlend{
        background-color: white;
    }
    .projectPopup .layoutSection3-hodlend img.teaserImage{
        height: 1054px;
    }
    .projectPopup .layoutSection3-hodlend .projectContactMe{
        text-decoration: underline;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-color: #212121;
        text-decoration-thickness: 2px;
    }
    .projectPopup .layoutSection3-hodlend .linkPreview{
        cursor: pointer;
    }
    

/* ----- PORTALOUTDOOR PROJECT ----- */

    /* --- LAYOUT 2 --- */
    .projectPopup .layoutSection2-portaloutdoor{
        background-color: #AFDDFE;
    }
    .projectPopup .layoutSection2-portaloutdoor .content{
        display: flex;
        justify-content: center;
    }
    .projectPopup .layoutSection2-portaloutdoor .content img.brandIdentityImage{
        height: 613px;
    }
    

    /* --- LAYOUT 3 --- */
    .projectPopup .layoutSection3-portaloutdoor .projectPreviewLink{
        text-decoration: underline;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-color: #212121;
        text-decoration-thickness: 2px;
    }
    .projectPopup .layoutSection3-portaloutdoor{
        background-color: white;
    }
    .projectPopup .layoutSection3-portaloutdoor img.uikitImage{
        height: 1719px;
        margin-left: -21px;
    }
    .projectPopup .layoutSection3-portaloutdoor img.uikitImagep2{
        height: 468px;
        margin-left: -24px;
        margin-bottom: 57px;
    }
    .projectPopup .layoutSection3-portaloutdoor img.uikitImagep3{
        height: 634px;
        margin-left: -24px;
        margin-bottom: 57px;
    }
    .projectPopup .layoutSection3-portaloutdoor .linkPreview{
        cursor: pointer;
    }





/* --- TABLET VERSION ---*/


@media screen and (max-width: 1365px) {

        /* COMMON PARTS */
        .projectPopup .tablet-version{
            display: block;
        }
        .projectPopup .tablet-version-inline{
            display: inline-block;
        }
        .projectPopup .desktop-version{
            display: none;
        }
        .projectPopup .mobile-version{
            display: none;
        }
        .projectPopup .layoutHeader .header{
            height: 24px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: flex-end;
        }
        .projectPopup .layoutHeader .header .closeIcon{
            height: 24px;
            width: 24px;
        }
        .layoutRestrictionsHeader{
            padding: 30px 32px 30px 32px;
            margin: auto;
            max-width: calc(768px - 64px);
        }
        .layoutRestrictions{
            max-width: calc(768px - 64px);
            padding: 0px 32px 0px 32px;
            margin: auto;
            display: flex;
            flex-direction: row;
        }
        .firstSection{
            padding-top: 84px;
        }
        .projectPopup .layoutSection1 .content p.projectTitle{
            margin: 0;
            padding: 0;
            font-size: 32px;
            line-height: 41px;
            font-weight: 600;
            color: #212121;
            margin-top: -8px;
        }
        .projectPopup .layoutSection1 .layoutRestrictions{
            flex-direction: column;
        }
        .projectPopup .layoutSection1 .content{
            padding: 0 116px 0 116px;
        }
        .projectPopup .layoutSection1 .layoutRestrictions .paddingColumns{
            padding: 0 116px 0 116px;
        }
        .projectPopup .layoutRestrictions .paddingColumns{
            flex: 0 0 116px;
        }
        .projectPopup .paddingColumns p.title{
            margin-bottom: 9px;
        }
        .projectPopup .paddingColumns p.anno{
            margin-bottom: 44px;
        }
        .projectPopup .paddingColumns p.pfinali{
            margin-top: 18px;
        }
        .projectPopup .paddingColumns p.pfinali + .subtitle{
            margin-bottom: 62px;
        }
        .projectPopup .layoutSection1 .content p.description{
            margin-top: 35px;
            margin-bottom: 66px;
        }
        .projectPopup .layoutSection1 .content img.projectPreviewImage{
            height: 355px;
            width: 100%;
        }
        .projectPopup .layoutRestrictions .content p.titles{
            margin-top: 66px;
            margin-bottom: 20px;
        }
        .projectPopup .layoutRestrictions .content p.descriptions{
            margin-bottom: 66px;
        }

        /* ----- OLLIN PROJECT ----- */

            .projectPopup .layoutSection2-ollin .content img.brandIdentityImage{
                height: 593px;
            }
            .projectPopup .layoutSection3-ollin img.uikitImage{
                height: 709px;
            }
            .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .image1{
                height: 391px;
                left: -10px;
            }
            .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .image2{
                height: 391px;
                right: -10px;
            }
            .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .imageDescription{
                padding-right: 20px;
            }
            .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .imageTitle.n2{
                right: -23px;
            }
            .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .imageDescription.n2{
                right: -23px;
            }
            .layoutSection4-ollin .layoutRestrictions .content{
                margin: 0px 80px 0px 80px;
            }
            .layoutSection4-ollin{

            }
        
        /* ----- HODLEND PROJECT ----- */
            
            .projectPopup .layoutSection2-hodlend .content img.brandIdentityImage{
                height: 648px;
            }
            .projectPopup .layoutSection3-hodlend img.teaserImage{
                height: 661px;
            }
        
        /* ----- PORTALOUTDOOR PROJECT ----- */
            
            .projectPopup .layoutSection2-portaloutdoor .content img.brandIdentityImage{
                height: 383px;
            }
            .projectPopup .layoutSection3-portaloutdoor img.uikitImage{
                height: 1074px;
                margin-left: -12px;
            }
            .projectPopup .layoutSection3-portaloutdoor img.uikitImagep2{
                height: 297px;
                margin-left: -14px;
                margin-bottom: 47px;
            }
            .projectPopup .layoutSection3-portaloutdoor img.uikitImagep3{
                height: 455px;
                margin-left: -14px;
                margin-bottom: 47px;
            }

        /* ----- 21PILOTS PROJECT ----- */

            .projectPopup .layoutSection2-21pilots .content img.moodboardImage{
                height: 592px;
            }
            .layoutSection4-21pilots .content .prototipo.desktop{
                height: 265px;
            }
            .layoutSection4-21pilots .content .prototipo.tablet{
                height: 469px;
                width: 352px;
            }
            .layoutSection4-21pilots .content .prototipo.mobile{
                height: 492px;
                width: 232px;
            }

}

/* --- MOBILE VERSION ---*/


@media screen and (max-width: 767px) {
    /* COMMON PARTS */
    .projectPopup .mobile-version{
        display: block;
    }
    .projectPopup .tablet-version{
        display: none;
    }
    .projectPopup .tablet-version-inline{
        display: none;
    }
    .projectPopup .desktop-version{
        display: none;
    }
    .projectPopup .slideUpIcon{
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 3;
        cursor: pointer;
        height: 24px;
        width: 24px;
        padding: 15px;
        background-color: #186c65;
    }
    .projectPopup .layoutHeader{
        z-index: 3;
    }
    .projectPopup .layoutHeader .header{
        height: 24px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-end;
    }
    .projectPopup .layoutHeader .header .closeIcon{
        cursor: pointer;
        height: 24px;
        width: 24px;
        padding: 15px;
        background-color: #186c65;
    }
    .layoutRestrictionsHeader{
        padding: 0px;
        margin: auto;
        max-width: 100%;
    }
    .layoutRestrictions{
        max-width: calc(360px - 38px);
        padding: 0px 19px 0px 19px;
        margin: auto;
        display: flex;
        flex-direction: row;
    }
    .firstSection{
        padding-top: 54px;
    }
    .projectPopup .layoutSection1 .layoutRestrictions{
        flex-direction: column;
    }
    .projectPopup .layoutSection1 .content{
        padding: 0;
        max-width: 280px;
    }
    .projectPopup .layoutSection1 .layoutRestrictions .paddingColumns{
        padding: 0;
    }
    .projectPopup .layoutSection1 .layoutRestrictions .paddingColumns .title:not(.anno), .subtitle {
        display: none;
    }
    .projectPopup .paddingColumns p.anno{
        margin-bottom: 29px;
    }
    .projectPopup .layoutRestrictions .paddingColumns{
        flex: 0 0 0px;
    }
    .projectPopup .layoutRestrictions .content p.titles{
        margin-top: 57px;
        margin-bottom: 21px;
    }
    .projectPopup .layoutSection1 .content p.description{
        font-size: 18px;
        line-height: 27px;
        margin-top: 38px;
        margin-bottom: 60px;
    }
    .projectPopup .layoutRestrictions .content p.descriptions{
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 60px;
        max-width: 280px;
    }
    .projectPopup .layoutSection1 .content img.projectPreviewImage{
        width: 100vw;
        height: auto;
        margin-left: calc(-19px - (100vw - 360px)/2);
    }


        /* --- OLLIN --- */
        
        .projectPopup .layoutSection2-ollin .content img.brandIdentityImage{
            width: 100vw;
            height: 100%;
            margin-left: -18px;
            max-width: calc(360px);
        }
        .projectPopup .layoutSection3-ollin img.uikitImage{
            width: 280px;
            height: auto;
        }
        .layoutSection4-ollin .layoutRestrictions{
            padding: 0;
            max-width: 100%;
        }
        .layoutSection4-ollin{
            background-color: white;
        }
        .projectPopup .layoutSection5-ollin .appAccessibilityPreview{
            flex-direction: column;
        }
        .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column{
            width: 100%;
        }
        .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .image1{
            max-width: 300px;
            height: auto;
            left: -12px;
        }
        .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .image2{
            max-width: 300px;
            height: auto;
            left: -12px;
            right: 0px;
            margin-top: 60px;
        }
        .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .imageTitle.n2{
            right: 0px;
        }
        .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .imageDescription.n2{
            right: 0px;
            max-width: 290px;
        }
        .projectPopup .layoutSection5-ollin .appAccessibilityPreview .column .imageDescription{
            max-width: 280px;
        }

    
        /* HODLEND */

        .projectPopup .layoutSection2-hodlend .content img.brandIdentityImage{
            width: 100vw;
            height: 100%;
            margin-left: -18px;
            max-width: calc(360px);
        }
        .projectPopup .layoutSection3-hodlend img.teaserImage{
            max-width: calc(360px - 38px);
            width: calc(100vw - 38px);
            height: auto;
        }

        /* PORTALOUTDOOR */
        .projectPopup .layoutSection2-portaloutdoor .content img.brandIdentityImage{
            width: 100vw;
            height: 100%;
            margin-left: -19px;
            max-width: calc(360px);
        }
        .projectPopup .layoutSection3-portaloutdoor img.uikitImage{
            width: 310px;
            margin-left: -19px;
            height: auto;
        }
        .projectPopup .layoutSection3-portaloutdoor img.uikitImagep2{
            width: 310px;
            margin-left: -19px;
            height: auto;
            margin-bottom: 37px;
        }
        .projectPopup .layoutSection3-portaloutdoor img.uikitImagep3{
            width: 300px;
            margin-left: -10px;
            height: auto;
            margin-bottom: 37px;
        }

        /* 21PILOTS */
        .projectPopup .layoutSection2-21pilots .content img.moodboardImage{
            width: 100vw;
            height: 100%;
            margin-left: -18px;
            max-width: calc(360px);
        }

        .layoutSection4-21pilots .selectorLayout{
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 16px;
        }
        .layoutSection4-21pilots .content .modeSelector{
            justify-content: space-between;
            width: 100%;
            padding-top: 24px;
        }
        .layoutSection4-21pilots .content .prototipo.desktop{
            height: 180px;
        }
        .layoutSection4-21pilots .content .prototipo.tablet{
            height: 319px;
            width: 239px;
        }
        .layoutSection4-21pilots .content .prototipo.mobile{
            height: 331px;
            width: 157px;
        }
}


/* particolari per dispositivi più piccoli di 360px */
@media screen and (max-width: 359px) {
    
    .projectPopup .layoutSection1 .content img.projectPreviewImage{
        width: 100vw;
        height: auto;
        margin-left: -19px;
    }

    .layoutSection4-21pilots .content .modeSelector .mode{
        font-size: 5vw;
        padding: 2.5vw 4.72222222222vw 2.5vw 4.72222222222vw;
    }

    .layoutSection4-21pilots .content .prototipo.desktop{
        width: 100%;
        max-height: 180px;
        height: calc((100vw - 38px) * (180/360));
    }
    .layoutSection4-21pilots .content .prototipo.tablet{
        width: 100%;
        max-width: 239px;
        max-height: 319px;
        height: calc((100vw - 38px) * (319/239));
    }
}