body{
  background-color: #186c65;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "Raleway";
  color: #ffc9c9;
  z-index: 0;
  overflow-y: overlay;
  overflow-x: hidden;
}
.mobile-version:not(.projectPopup){
  display: none;
}
.tablet-version:not(.projectPopup){
  display: none;
}
.desktop-version:not(.projectPopup){
  display: block;
}

::-webkit-scrollbar {
    width:10px;
}
::-webkit-scrollbar-track {
    -webkit-border-radius:5px;
    border-radius:5px;
    background:rgba(255,255,255,0.1);
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius:5px;
    border-radius:5px;
    background:rgba(0,0,0,0.2);
}
::-webkit-scrollbar-thumb:hover {
    background:rgba(0,0,0,0.4);
}

.Homepage{
  max-width: calc(1366px - 60px);
  height: auto;
  margin: auto;
  padding: 0;
  overflow:auto;
  padding: 48px 30px 48px 30px;
}

.introductionTitle{
  font-weight: 300;
  font-size: 1.125em;
  height: 21px;
  display: inline;
}

.introductionSection{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.introductionSection .left{
  height: auto;
  width: 74.65%;
}
.introductionSection .left h1{
  font-weight: 500;
  font-size: 4em;
  margin:80px 0 0 0;
}
.introductionSection .left .p1{
  font-size: 2em;
  font-weight: 300;
  margin: 44px 0 0 0;
  line-height: 40px;
}
.introductionSection .left .p2{
  font-size: 2em;
  font-weight: 300;
  margin: 35px 0 0 0;
  line-height: 40px;
}
.introductionSection .left .p2 span.underline{
  text-shadow:
    2px 2px #186c65,
    2px -2px #186c65,
    -2px 2px #186c65,
    -2px -2px #186c65;
}
.introductionSection .left p .underline{
  font-weight: 600;
}
.email-link{
  cursor: pointer;
}
.introductionSection .left p .underlinedIntro{
  font-weight: 600;
}
.introductionSection .right{
  height: auto;
  width: 24.11%;
  background: url("../media/giulia_di_lonardo.jpg") no-repeat;
  background-size: cover;
  height: 315px;
  width: 315px;
  margin-top: -21px;
}

.projectSection{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.projectSection .project{
  height: 560px;
  width: 590px;
}
.projectSection .project.n1{
  margin: 110px 0 0 0;
  cursor: pointer;
}
.projectSection .project.n2{
  margin: 254px 0 0 0;
  cursor: pointer;
}
.projectSection .project.n3{
  margin: 100px 0 0 0;
  cursor: pointer;
}
.projectSection .project.n4{
  margin: 244px 0 0 0;
  cursor: pointer;
}
.projectSection .project .projectImage{
  background-color: #ffc9c9;
  width: 590px;
  height: 444px;
  margin: 0;
  padding: 0;
}
.projectSection .project .projectImage img{
  height: 100%;
  width: 100%;
}
.projectSection .project .projectTitle{
  font-size: 32px;
  font-weight: 400;
  margin: 17px 0 0 0;
  padding: 0;
  line-height: 37px;
}
.projectSection .project:hover{
  color: #ffe3e3;
}
.projectSection .project:hover .projectTitle span{
  text-decoration-color: #ffe3e3;
}
.projectSection .project .projectTitle span{
  text-decoration: underline;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: #ffc9c9;
    text-decoration-thickness: 1px;
}
.projectSection .project .projectInfo{
  padding: 0;
  margin: 4px 0 0 0;
  font-size: 18px;
  font-weight: 300;
}

.linkSection{
  margin: 184px 0 0 0;
}
.linkSection .links{
  font-size: 48px;
  margin: 0;
  line-height: 0.85;
  font-weight: 500;
  cursor: pointer;
}
.linkSection .links a{
  text-decoration: none;
  color: inherit;
}
.linkSection .links a:hover{
  color: #ffe3e3;
}
.linkSection .links:not(.first){
  margin-top:30px;
}
.linkSection .underline{
  border-bottom: 2px solid currentColor;
  display: inline-block;
  line-height: 0.85;
  padding-bottom: 0.6px;
}
.underline{
  border-bottom: 1px solid currentColor;
  display: inline-block;
  line-height: 0.85;
  padding-bottom: 0.6px;
}

.footerSection{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 101px 0 0 0;
}
.footerSection p{
  font-size: 18px;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
.footerSection p.copyright{
  margin: 0;
}

.not-selectable{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;
}




/* --- TABLET VERSION ---*/


@media screen and (max-width: 1365px) {
  .Homepage{
    max-width: calc(768px - 64px);
    padding: 32px 32px 32px 32px;
  }
  .tablet-version:not(.projectPopup){
    display: block;
  }
  .mobile-version:not(.projectPopup){
    display: none;
  }
  .desktop-version:not(.projectPopup){
    display: none;
  }
  .introductionSection .right{
    order: -1;
    height: 704px;
    width: 704px;
    margin-top: 20px;
  }
  .introductionSection .left{
    width: 100%;
  }
  .introductionSection .left h1{
    font-size: 48px;
    line-height: 52px;
    margin: 53px 0 0 0;
  }
  .introductionSection .left .p1{
    font-size: 24px;
    line-height: 30px;
    margin: 33px 0 0 0;
  }
  .introductionSection .left .p2{
    font-size: 24px;
    line-height: 30px;
    margin: 20px 0 0 0;
  }
  .introductionSection .left .underlinedIntro{
    text-decoration: underline;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: #ffc9c9;
    text-decoration-thickness: 1px;
  }
  .projectSection{
    justify-content: center;
  }
  .projectSection .project{
    /*width: 100%;*/
    width: 704px;
    height: auto;
  }
  .projectSection .project .projectImage{
    /*width: 100%;*/
    width: 704px;
    height: 531px;
  }
  .projectSection .project .projectTitle{
    font-size: 24px;
    margin: 19px 0 0 0;
    line-height: 30px;
  }
  .projectSection .project .projectInfo{
    margin: 6px 0 0 0;
    font-size: 18px;
  }
  .projectSection .project.n1{
    margin: 91px 0 0 0;
  }
  .projectSection .project.n2{
    margin: 60px 0 0 0;
  }
  .projectSection .project.n3{
    margin: 60px 0 0 0;
  }
  .projectSection .project.n4{
    margin: 60px 0 0 0;
  }
  .linkSection{
    margin: 110px 0 0 0;
  }
  .linkSection .links{
    font-size: 48px;
  }
  .linkSection .links:not(.first){
    margin-top:28px;
  }
  .footerSection{
    margin: 57px 0 76px 0;
    flex-direction: column;
  }
  .footerSection p.copyright{
    margin-top:7px;
  }
  .contactButtonSection.mobile-version{
    background-color: #ffc9c9;
    color: #186c65;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 80px;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .contactButtonSection .contactButton{
    max-width: calc(768px - 64px);
    padding: 0 19px 0 19px;
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .contactButtonSection .contactButton p{
    margin: 0;
  }
  .arrow{
    flex-grow: 2;
    margin-right: -61px;
    background: url("../media/arrow-tablet.svg") center no-repeat;
    background-size:contain;
    height: 13px;
  }
}





/* --- MOBILE VERSION --- */


@media screen and (max-width: 767px) {
  .Homepage{
    max-width: calc(360px - 38px);
    padding: 32px 19px 32px 19px;
  }
  .mobile-version:not(.projectPopup){
    display: block;
  }
  .tablet-version:not(.projectPopup){
    display: none;
  }
  .desktop-version:not(.projectPopup){
    display: none;
  }
  .introductionSection .right{
    order: -1;
    height: 316px;
    width: 322px;
    margin-top: 20px;
  }
  .introductionSection .left{
    width: 100%;
  }
  .introductionSection .left h1{
    font-size: 48px;
    line-height: 52px;
    margin: 53px 0 0 0;
  }
  .introductionSection .left .p1{
    font-size: 24px;
    line-height: 30px;
    margin: 33px 0 0 0;
  }
  .introductionSection .left .p2{
    font-size: 24px;
    line-height: 30px;
    margin: 20px 0 0 0;
  }
  .introductionSection .left .underlinedIntro{
    text-decoration: underline;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: #ffc9c9;
    text-decoration-thickness: 1px;
  }
  
  .projectSection{
    justify-content: center;
  }
  .projectSection .project{
    width: 100%;
    height: auto;
  }
  .projectSection .project .projectImage{
    width: 100%;
    height: auto;
    display: flex;
  }
  .projectSection .project .projectTitle{
    font-size: 24px;
    margin: 19px 0 0 0;
    line-height: 30px;
  }
  .projectSection .project .projectInfo{
    margin: 6px 0 0 0;
    font-size: 18px;
  }
  .projectSection .project.n1{
    margin: 58px 0 0 0;
  }
  .projectSection .project.n2{
    margin: 60px 0 0 0;
  }
  .projectSection .project.n3{
    margin: 60px 0 0 0;
  }
  .projectSection .project.n4{
    margin: 60px 0 0 0;
  }
  .linkSection{
    margin: 110px 0 0 0;
  }
  .linkSection .links{
    font-size: 32px;
  }
  .linkSection .links:not(.first){
    margin-top:28px;
  }
  .footerSection{
    margin: 57px 0 76px 0;
    flex-direction: column;
  }
  .footerSection p.copyright{
    margin-top:24px;
  }
  .contactButtonSection.mobile-version{
    background-color: #ffc9c9;
    color: #186c65;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 64px;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .contactButtonSection .contactButton{
    max-width: calc(360px - 38px);
    padding: 0 19px 0 19px;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .contactButtonSection .contactButton p{
    margin: 0;
  }
  .arrow{
    flex-grow: 2;
    background: url("../media/arrow.svg") center no-repeat;
    background-size:contain;
    margin-right: -40px;
    height: 13px;
  }
}

/* particolari per dispositivi più piccoli di 360px */
@media screen and (max-width: 359px) {
  .arrow{
    height: 3.6111111111111vw;
    margin-right: -11.11111111111vw;
  }
  .contactButtonSection .contactButton{
    font-size: 5vw;
  }
}