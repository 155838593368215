.ollin-project-page .gallery {
  width: 100%;
  height: 100%;
}

.ollin-project-page .flickity-viewport { 
  height: 100%; 
}

.ollin-project-page .gallery-cell {
  width: 100%;
  height: 100%;
  padding: 81px 55px 144px 55px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.ollin-project-page .gallery-cell .gallery-cell-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 39px;
}

.ollin-project-page .gallery-cell .gallery-cell-text p.title{
    font-size: 32px;
    font-weight: 600;
    color:#212121;
    margin: 0;
}
.ollin-project-page .gallery-cell .gallery-cell-text p.description{
    font-size: 24px;
    font-weight: 300;
    color:#212121;
    margin: 0;
    margin-top: 35px;
    line-height: 34px;
}

.ollin-project-page .gallery-cell .imagePreview{
    height: 100%;
    width: auto;
    margin-left: -25px;
}

.ollin-project-page .flickity-prev-next-button {
  width: 54px;
  height: 54px;
  border: solid 1px #212121;
  top: calc(100% - 117px);
  color: #212121
}
.ollin-project-page .flickity-prev-next-button.next{
    right: -81px;
}
.ollin-project-page .flickity-prev-next-button.previous{
    left: -81px;
}
.ollin-project-page .flickity-prev-next-button .flickity-button-icon{
  left: 30%;
  top: 30%;
  width: 40%;
  height: 40%;
}


/* arrow color */
.ollin-project-page .flickity-prev-next-button .arrow {
  fill: #212121;
}
.ollin-project-page .flickity-prev-next-button.no-svg {
  color: white;
}
.ollin-project-page .flickity-prev-next-button:hover {
  background: transparent;
}
.ollin-project-page .flickity-button{
  background-color: transparent;
}
/* hide disabled button */
.ollin-project-page .flickity-prev-next-button:disabled {
  /*display: none;*/
}

/* position dots in gallery */
.ollin-project-page .flickity-page-dots {
  bottom: 0px;
}
/* white circles */
.ollin-project-page .flickity-page-dots .dot {
  width: calc((100% - 110px)/5);
  height: 2px;
  opacity: 1;
  background: #bdbdbd;
  border-radius: 0;
  margin: 0px 0px 113px 0px;
}
/* fill-in selected dot */
.ollin-project-page .flickity-page-dots .dot.is-selected {
  background: #212121;
}





/* --- TABLET MODE --- */
@media screen and (max-width: 1365px) {

  .ollin-project-page .gallery-cell{
    padding: 14px 36px 124px 36px; 
  }
  .ollin-project-page .gallery-cell {
    flex-direction: column;
    justify-content: center;
  }
  .ollin-project-page .flickity-prev-next-button.next{
    right: -54px;
  }
  .ollin-project-page .flickity-prev-next-button.previous{
    left: -54px;
  }
  .ollin-project-page .flickity-page-dots .dot {
    width: calc((100% - 72px)/5);
  }
  .ollin-project-page .gallery-cell .imagePreview{
    width: 20.50vh;
    height: auto;
    margin-left: -1.95vh;
  }
  .ollin-project-page .gallery-cell .gallery-cell-text p.title{
    font-size: 2.93vh;
  }
  .ollin-project-page .gallery-cell .gallery-cell-text p.description{
    margin-top: 0.97vh;
    font-size: 2.15vh;
    line-height: 3.125vh;
  }
  .ollin-project-page .gallery-cell .gallery-cell-text{
    margin-left: 0;
    margin-top: 10px;
  }
  .ollin-project-page .flickity-page-dots .dot{
    margin-bottom: 78px;
  }
  .ollin-project-page .flickity-prev-next-button{
    top: calc(100% - 82px);
  }

}




/* --- MOBILE MODE --- */


@media screen and (max-width: 767px) {

  .ollin-project-page .flickity-button{
    display: none;
  }
  .layoutSection4-ollin .layoutRestrictions .content{
    margin: 0;
  }
  .ollin-project-page .gallery-cell{
    padding: 0px 19px 55px 19px;
    background-color: #fbe7e6;
    width: calc(100% - 20px);
    margin-right: 7px;
    justify-content: center;
  }
  .ollin-project-page .gallery-cell .imagePreview{
    width: 20.83vh;
    height: auto;
    margin-left: -1.875vh;
  }
  .ollin-project-page .gallery-cell .gallery-cell-text p.title{
    font-size: 3.75vh;
  }
  .ollin-project-page .gallery-cell .gallery-cell-text p.description{
    font-size: 2.5vh;
    line-height: 3.90vh;
    padding-right: 29px;
  }
  .ollin-project-page .flickity-page-dots .dot{
    margin-bottom: 24px;
  }
  .ollin-project-page .flickity-page-dots .dot {
    width: calc((100% - 80px)/5);
  }
  .ollin-project-page .flickity-page-dots{
    text-align: left;
    padding-left: 20px;
  }

}